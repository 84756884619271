.cd-header {
	background: white;
	background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(100%, rgba(255, 255, 255, 0)));
	background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
	background: -o-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
	background: -ms-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
	box-shadow: none;
	padding: 15px 30px;
	height: 1 20px;
}
.client-detail-sec {
	margin: -60px 0 0 0;
}
.cd-map-sec {
	padding: 0;
	height: auto;
	#map {
		width: 100%;
		min-height: 500px;
	}
}
.cd-page {
	padding: 0 0 60px 0;
	background: #ffffff;
}
.cd-inner-section {
	hr {
		margin: 20px 0;
	}
}
.label-txt {
	font-size: 14px;
	color: #95989A;
	margin: 7px 0 0 0;
	display: block;
}
.chk-distance {
	box-shadow: 0px 3px 10px #0000000D;
	width: 80%;
	margin: 10px 0 30px 0;
	.input-group {
		box-shadow: 0px 3px 10px #0000000D;
		border: 1px solid #E9E9E9;
		border-radius: 5px;
		background-color: #fff;
		input {
			border: none;
			background-color: #fff;
			height: auto;
			padding: 30px 15px 30px 60px;
			background-image: url(../images/map-marker-alt-solid.svg);
			background-position: 30px center;
			background-repeat: no-repeat;
		}
		span {
			border: none;
			background-color: #fff;
		}
		.input-group-append {
			border-left: 1px solid #E9E9E9;
			margin: 0;
			padding: 0 30px;
			.txt-sec {
				font-size: 8px;
				color: #95989A;
				text-transform: uppercase;
				span {
					display: block;
					font-size: 15px;
					color: #4A494A;
					text-transform: capitalize;
					strong {
						font-weight: 700;
						font-size: 24px;
					}
				}
			}
		}
	}
}
.practician-name {
	.trusted-txt {
		font-size: 16px;
		color: #e62b4a;
		font-weight: 400;
	}
	.p-name {
		font-size: 24px;
		font-weight: 700;
		line-height: 35px;
	}
	.p-address {
		color: #95989A;
	}
	.ratting-value {
		margin-top: 10px;
		font-size: 10px;
		font-weight: 700;
		color: #95989A;
		strong {
			font-size: 40px;
			color: #4A494A;
		}
		span {
			font-size: 11px;
			color: #4A494A;
			font-weight: 400;
		}
	}
	.ratting-star {
		margin-bottom: 10px;
		i {
			color: #FF8C00;
			margin: 0 2px 0 0;
			font-size: 14px;
		}
		span {
			color: #95989A;
			font-size: 14px;
			margin: 0 0 0 7px;
		}
	}
}
.helpful-review {
	position: relative;
	padding: 0 30px;
	&::after {
		content: '';
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		background-image: url(../images/quote-black.svg);
		background-repeat: no-repeat;
		background-size: 15px auto;
		background-position: left 4px;
		transform: rotate(180deg);
	}
	&::before {
		content: '';
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		background-image: url(../images/quote-black.svg);
		background-repeat: no-repeat;
		background-size: 15px auto;
		background-position: left 4px;
	}
	.title-txt {
		font-size: 18px;
		font-weight: 700;
		color: #FF8C00;
	}
	p {
		font-style: italic;
		margin: 15px 0 0 0;
		padding: 0 0 15px 0;
	}
}
.highlights-listing {
	li {
		.media {
			box-shadow: 0px 3px 10px #0000000D;
			border: 1px solid #E9E9E9;
			border-radius: 5px;
			background-color: #fff;
			padding: 20px 30px;
			.media-img {
				width: 40px;
				height: 40px;
				padding: 2px;
				text-align: center;
			}
			.media-body {
				font-size: 16px;
				font-weight: 700;
				color: #4A494A;
				span {
					color: #95989A;
					display: block;
					font-size: 14px;
					font-weight: 400;
					line-height: 24px;
				}
			}
		}
	}
}
.services-offered {
	margin-bottom: 30px;
	.media {
		position: relative;
		padding: 12px 0;
		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 1px;
			top: 0;
			bottom: 0;
			right: -30px;
			left: auto;
			margin: auto 0;
			background-color: #E9E9E9;
		}
		.media-img {
			width: 40px;
			height: 40px;
			padding: 2px;
			text-align: center;
		}
		.media-body {
			font-size: 16px;
			font-weight: 700;
			color: #4A494A;
			span {
				color: #95989A;
				display: block;
				font-size: 14px;
				font-weight: 400;
				line-height: 24px;
			}
		}
	}
	.service-selected-list {
		margin: 0;
		padding: 0 0 0 30px;
		li {
			font-size: 16px;
			font-weight: 700;
			color: #4A494A;
		}
	}
	.btn {
		padding-left: 0;
		padding-right: 0;
	}
	.all-services {
		position: relative;
		z-index: 0;
		.close-list {
			position: absolute;
			top: -20px;
			right: 0;
			left: auto;
			bottom: auto;
			margin: 0;
		}
		.all-service-list {
			-moz-column-count: 2;
			-moz-column-gap: 20px;
			-webkit-column-count: 2;
			-webkit-column-gap: 20px;
			column-count: 2;
			column-gap: 20px;
			margin: 0;
			padding: 0;
			li {
				font-size: 18px; 
				color: #4A494A;
				padding: 4px 0;
			}
			li.selected {
				text-decoration: underline;
				color: #16CF50;
			}
		}
	}
}
.insurance-details {
	.media {
		margin: 0 0 45px 0;
	}
	.title-txt {
		font-weight: 700;
		font-size: 18px;
		color: #4A494A;
	}
	p {
		margin: 0;
	}
	.label-txt {
		color: #4A494A;
	}
}
.financing-plans {
	p {
		width: 70%;
		margin: 0;
	}
}
.img-gallery {
	margin-bottom: 30px;
	.img-box {
		background-color: #fff;
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		border: 1px solid #fff;
		img {
			max-width: 100%;
		}
	}
}
.practice-features {
	ul {
		-moz-column-count: 3;
		-moz-column-gap: 30px;
		-webkit-column-count: 3;
		-webkit-column-gap: 30px;
		column-count: 3;
		column-gap: 30px;
		padding: 0;
		margin: 0;
		li {
			font-size: 18px;
			font-weight: 700;
			color: #4A494A;
			padding: 4px 0;
		}
	}
}
.dr-profiles {
	.profile-box {
		box-shadow: 0px 3px 10px #0000000D;
		border: 1px solid #E9E9E9;
		border-radius: 5px;
		padding: 30px;
		margin: 30px 0;
		.media {
			.media-img {
				width: 90px;
				height: 90px;
				border: 3px solid #FFFFFF;
				border-radius: 50%;
				overflow: hidden;
			}
			.media-body {
				.dr-profile-visible {
					padding: 20px 0;
					position: relative;
					z-index: 0;
					.view-btn {
						position: absolute;
						text-align: left;
						top: 0;
						bottom: 0;
						right: 0;
						left: auto;
						background-color: #00C9A71A;
						border: 1px solid #00C9A726;
						height: 30px;
						border-radius: 5px;
						font-size: 14px;
						color: #4A494A;
						line-height: 18px;
						min-width: 120px;
						background-image: url(../images/view-profile-arrow.svg);
						background-position: calc(100% - 0.75rem) center;
						background-repeat: no-repeat;
						margin: auto 0;
					}
					.dr-name {
						font-size: 18px;
						line-height: 28px;
						font-weight: 700;
						color: #4A494A;
					}
					.trusted-txt {
						font-size: 16px;
						color: #e62b4a;
						font-weight: 400;
						margin: 0;
					}
				}
				.dr-profile-hidden {
					.profile-desc {
						border-top: 1px solid #E9E9E9;
						border-bottom: 1px solid #E9E9E9;
						padding: 30px 0;
						margin: 15px 0 30px 0;
						p {
							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}
.patient-reviews {
	.title-sec {
		margin: 60px 0 0 0;
	}
	.media-body {
		p {
			font-size: 22px;
			margin: 0;
		}
	}
	.media-img {
		.ratting-value {
			font-size: 10px;
			font-weight: 700;
			color: #95989A;
			strong {
				font-size: 40px;
				color: #4A494A;
			}
			span {
				font-size: 11px;
				color: #4A494A;
				font-weight: 400;
			}
		}
	}
	.ratting-star {
		i {
			color: #FF8C00;
			margin: 0 2px 0 0;
			font-size: 14px;
		}
		span {
			color: #95989A;
			font-size: 14px;
			margin: 0 0 0 7px;
		}
	}
}
.cd-review-sec {
	.media {
		border-top: 1px solid #E9E9E9;
		margin: 30px 0 0 0;
		padding: 30px 0 0 0;
	}
	.media-img {
		height: 100px;
		width: 100px;
		overflow: hidden;
		box-shadow: 0px 5px 5px #0000001A;
		border: none;
		border-radius: 50%;
		margin: 0 30px 0 0;
	}
	.ratting-star {
		margin: 0 0 15px 0;
		i {
			color: #FF8C00;
			margin: 0 3px 0 0;
			font-size: 14px;
		}
	}
	.review-content {
		margin: 0 0 15px 0;
	}
	.btn {
		margin: 45px 0 0 0;
	}
}
.cd-dr-img {
	margin: 0 0 15px 0;
	.img-box {
		height: 90px;
		width: 90px;
		overflow: hidden;
		border: 3px solid #FFFFFF;
		border-radius: 50%;
		margin: 0 0 0 -15px;
		display: inline-block;
	}
	.img-box.lmo {
		background: #f9f9f9;
		text-align: center;
		line-height: 80px;
		font-size: 24px;
		font-weight: 600;
	}
}
.patients-no {
	margin: 0 0 30px 0;
	font-weight: 700;
}
.open-hours {
	margin: 30px 0;
	background-color: #FFFFFF;
	box-shadow: 0px 3px 10px #0000000D;
	.title-sec {
		background-color: #DE443626;
		color: #e62b4a;
		text-align: center;
		padding: 25px;
		font-size: 18px;
		line-height: 18px;
		font-weight: 700;
		border: 1px solid #DE443626;
		border-radius: 3px 3px 0 0;
	}
	.desc-sec {
		padding: 25px 40px;
		border: 1px solid #E9E9E9;
		border-radius: 0 0 3px 3px;
		border-top: none;
		ul {
			li {
				display: flex;
				-ms-flex-pack: justify !important;
				justify-content: space-between !important;
				position: relative;
				margin: 15px 0;
				color: #95989A;
				font-weight: 400;
				font-size: 16px;
				&::after {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: #E9E9E9;
					top: auto;
					bottom: 12px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
				span {
					background: #fff;
					display: inline-block;
					position: relative;
					z-index: 1;
					&:first-child {
						text-align: left;
						background-color: #fff;
						padding: 0 7px 0 0;
					}
					&:last-child {
						text-align: right;
						padding: 0 0 0 7px;
						background-color: #fff;
					}
				}
			}
			li.active {
				color: #16CF50;
				font-weight: 700;
			}
		}
	}
}
.info_custom {
	display: flex;
	align-items: center;
	width: 250px;
	.info_img {
		max-width: 50px;
		margin: 0 10px 0 0;
	}
	.info_cnt {
		font-weight: 700;
	}
	.info_rating {
		margin: 5px 0 0 0;
		i {
			color: #FF8C00;
		}
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.highlights-listing {
		li {
			.media {
				padding: 20px;
			}
		}
	}
	.practice-features {
		ul {
			-moz-column-count: 2;
			-moz-column-gap: 30px;
			-webkit-column-count: 2;
			-webkit-column-gap: 30px;
			column-count: 2;
			column-gap: 30px;
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	#sidebarClient {
		position: absolute;
		top: 10px;
		right: 15px;
		z-index: 1;
		width: 36%;
		.cd-dr-img {
			margin: 0 0 7px 0;
			.img-box {
				width: 80px;
				height: 80px;
			}
		}
		.patients-no {
			margin: 0 0 15px 0;
			font-size: 14px;
		}
		#footerBtn {
			padding: 10px;
			font-size: 14px;
			img {
				height: 16px;
				width: auto;
			}
		}
		.open-hours {
			.desc-sec {
				padding: 20px 30px;
			}
			.title-sec {
				font-size: 16px;
				padding: 15px;
			}
		}
	}
	.chk-distance {
		width: 60%;
		.input-group {
			input {
				padding: 30px 15px 30px 40px;
				background-position: 15px center;
			}
			.input-group-append {
				padding: 0 15px;
			}
		}
	}
	.practician-name.media {
		display: block;
		width: 60%;
		.media-img {
			margin-top: 15px;
		}
	}
	.helpful-review {
		width: 60%;
		+ {
			hr {
				width: 60%;
			}
		}
	}
	.practician-name {
		+ {
			hr {
				width: 60%;
			}
		}
	}
	.practice-features {
		ul {
			li {
				font-size: 16px;
			}
		}
	}
	.services-offered {
		.all-services {
			.all-service-list {
				li {
					font-size: 16px;
				}
			}
		}
	}
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.cd-page {
		padding: 0 0 90px 0;
		position: relative;
	}
	.cd-header {
		padding: 10px 0;
		.navbar-brand {
			img {
				max-width: 80px;
				height: auto;
				width: 100%;
			}
		}
	}
	.client-detail-sec {
		margin: 0;
	}
	.chk-distance {
		width: 100%;
		margin: -36px 0 30px 0;
		.input-group {
			input {
				background-position: 12px center;
				padding: 20px 15px 20px 30px;
				background-size: 10px auto;
				font-size: 14px;
				line-height: 14px;
			}
			.input-group-append {
				padding: 0 5px;
				.txt-sec {
					span {
						strong {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
	.cd-dr-img {
		text-align: center;
		margin: 0 0 15px 30px;
		.img-box {
			height: 80px;
			width: 80px;
		}
	}
	.practician-name {
		display: block;
		text-align: center;
		.media-body {
			margin-right: 0 !important;
		}
		.media-img {
			margin-right: 0 !important;
		}
		.p-name {
			font-size: 24px;
			line-height: 32px;
		}
		.p-address {
			font-size: 14px;
		}
	}
	.cd-review-sec {
		.media {
			display: block;
		}
		.media-img {
			margin: 0 auto 15px auto;
			height: 60px;
			width: 60px;
		}
		.ratting-star {
			img {
				max-width: 16px;
			}
		}
		.review-content {
			font-size: 14px;
		}
		.review-date {
			font-size: 14px;
		}
	}
	.patient-reviews {
		.title-sec {
			display: block;
		}
		.media-body {
			p {
				font-size: 16px;
			}
		}
	}
	.practice-features {
		ul {
			-moz-column-count: auto;
			-moz-column-gap: 0px;
			-webkit-column-count: auto;
			-webkit-column-gap: 0px;
			column-count: auto;
			column-gap: 0px;
		}
	}
	.services-offered {
		.all-services {
			.all-service-list {
				-moz-column-count: auto;
				-moz-column-gap: 0px;
				-webkit-column-count: auto;
				-webkit-column-gap: 0px;
				column-count: auto;
				column-gap: 0px;
			}
		}
		.media {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			&::after {
				display: none;
			}
		}
		.service-selected-list {
			margin: 15px 0;
			li {
				line-height: 32px;
			}
		}
	}
	.highlights-listing {
		-moz-column-count: auto;
		-moz-column-gap: 0px;
		-webkit-column-count: auto;
		-webkit-column-gap: 0px;
		column-count: auto;
		column-gap: 0px;
		li {
			.media {
				padding: 20px;
			}
		}
	}
	.cd-sidebar {
		.patients-no {
			text-align: center;
			margin: 15px 0;
		}
		.open-hours {
			margin: 0;
		}
	}
	.financing-plans {
		p {
			width: 100%;
		}
	}
	.open-hours {
		.desc-sec {
			padding: 25px 30px;
		}
	}
	.section-title {
		font-size: 22px;
		line-height: 30px;
	}
	.insurance-details {
		padding: 0 0 45px 0;
		.media {
			margin: 30px 0 15px 0;
		}
		.title-txt {
			font-size: 16px;
		}
		p {
			font-size: 14px;
			line-height: 22px;
		}
	}
	.dr-profiles {
		.profile-box {
			padding: 15px;
			.media {
				.media-img {
					height: 50px;
					width: 50px;
					margin-right: 7px !important;
				}
				.media-body {
					.dr-profile-visible {
						.view-btn {
							font-size: 0;
							min-width: 1px;
							background-position: center;
						}
						padding: 0;
						.dr-name {
							font-size: 16px;
						}
						.trusted-txt {
							font-size: 14px;
							margin: 0 0 7px 0;
							img {
								height: 14px;
								width: auto;
							}
						}
					}
				}
			}
		}
	}
	#footerBtn {
		position: fixed;
		bottom: 0;
		border-radius: 0;
		z-index: 9;
	}
}
