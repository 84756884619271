/* AppointmentCalender.css */

/* Base styles for the calendar */
.react-calendar {
  width: 100%;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
}

/* Header styles */
.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

/* Month view styles */
.react-calendar__month-view__weekdays {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #ececec;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 10px;
    color: rgba(0, 0, 0, 0.6);
    /*border-left: 1px solid #eee;
    border-top: 1px solid #eee;*/
}
/*.react-calendar__month-view__weekdays__weekday:nth-child(7n){
  border-right: 1px solid #eee;
}*/
.react-calendar__tile {
    width: 100% !important;
    padding: 10px 0px;
    text-align: center;
    line-height: 16px;
    border-radius: 50% !important;
    background: #fff !important;
    max-width: 40px !important;
    height: 40px !important;
}
.react-calendar__tile:hover {
    background: #0267D4 !important;
    color: #fff !important;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  border-radius: 0%;
}

.react-calendar__tile--now {
      background: #3054cb !important;
    border-radius: 50%;
    font-weight: bold;
    color: white;
}

.react-calendar__tile--active {
  background: #0267D4;
  border-radius: 50%;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--hasActive {
  background: #f0f0f0;
}

.react-calendar__tile--range {
  background: #6f48eb;
  color: white;
  border-radius: 0;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  border-radius: 0%;
  background: #6f48eb;
  color: white;
}

/* Additional styles */
.highlighted {
  border: 0px solid #6f48eb !important;
    background-color: #cdebf5 !important;
    border-radius: 50% !important;
    color: #3054cb;
    font-weight: 600;
}
.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}
.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    background: #323344 !important;
    color: #fff !important;
    display: none !important;
}
.react-calendar__navigation__label__labelText--from {
    color: #323344;
    font-size: 18px;
    font-weight: 700;
}
.react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
    font-size: 30px !important;
    background: #b7e2f0b0 !important;
    color: #3054cb !important;
    border-radius: 50%;
    min-width: 42px !important;
    height: 42px !important;
}
.time-slot {
    /* height: 250px; */
    overflow: auto;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr)
}
.time-slot div a {
    font-size: 13px;
    background-color: #0267D4;
    color: #fff;
    border-radius: 4px !important;
    padding: 7px 5px !important;
    text-align: center !important;
    cursor: pointer;
    border: none !important;
    display: block;
    margin: 0px auto 10px;
    max-width: 150px;
}
.time-slot div a.active {
    background-color: #4a494a;
    color: #fff;
    font-weight: 500;
    pointer-events: none;
}
.prev_btn, .next_btn {
    border: none;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin: 10px;
    padding: 5px;
}
.backto_btn {
    border: none;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin: 24px auto 0 !important;
    text-align: center;
    display: block;
}
.react-calendar__month-view__days {
    display: grid !important;
    grid-gap: 10px;
    grid-template-columns: repeat(7, 1fr);
}

/*Responsive CSS*/

@media only screen and (max-width:375px){
.react-calendar__navigation__label__labelText--from{
    font-size: 14px !important;
}
.react-calendar__navigation button{
  min-width: 35px;
}
.react-calendar__month-view__weekdays__weekday{
  padding: 10px 0px !important;
  font-size: 10px;
}
.main-grid-sec .practice-sidebar #availability-tab .nav-link {
    font-size: 16px;
}

}

@media only screen and (max-width:767px){
.react-calendar__tile {
    max-width: 30px !important;
    height: 30px !important;
    line-height: 12px;
}
}


