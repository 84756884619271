.main-footer {
    font-size: 1rem;
    strong 
    {
        @extend .mb-md-4, .mb-2, .d-block;
    }
    li:not(:last-child) 
    {
        @extend .mb-2;
    }
    p 
    {
        max-width: 260px;
        @include media-breakpoint-down(md) {
            max-width: none;
        }
    }
}