/* --- Dentist home page start --- */

.d-banner 
{
    background-image: url(../images/dentist-banner.png);
    position: relative;
    z-index: 0;
    .bg-overlay
    {
        position: absolute;
        top:0;
        bottom: 0;
        margin: auto 0;
        left: 0;
        width: 50%;
        background: -moz-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(128,128,128,0.7)), color-stop(100%, rgba(0,0,0,0)));
        background: -webkit-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: -o-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: -ms-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: linear-gradient(to right, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#808080', endColorstr='#000000', GradientType=1 );
        z-index: -1;
    }
    .txt-section h1
    {
        font-size: 60px;
        line-height: 70px;
        font-weight:700;
    }
}
.d-dashboard
{
    background-color: #fff;
    color: $font-color;
    .dentist_pt h2 , .dentist_pt p , .dashboard p
    {
        color: $font-color;
    }
    .dentist_pt ul.big_list li
    {
        background-image: url(../images/list-icon-black.svg);
        color: $font-color;
    }
}
.patients-dashboard
{
    .txt-sec
    {
        width: 80%;
        margin: auto;
        text-align: left;
        position: relative;
        z-index: 0;
        padding: 0 0 0 80px;
        &::after
        {
            background-color: #F2F9FF;
            position: absolute;
            width: 290px;
            height: 290px;
            content: '';
            left: 0;
            top:0;
            bottom: 0;
            margin: auto 0;
            border-radius: 50%;
            z-index: -1;
        }
        p
        {
            span
            {
                display: block;
                font-size: 18px;
                font-weight: 700;
            }
            font-weight: 300;
            margin: 0;
        }
    }
}
.d-testimonials
{
    background-color: #F2F9FF;
    margin: 0 0 0 0;
    .dentist_slide
    {
        position: relative; 
        margin: 15px 0;
        &::after
        {
            content: '';
            position: absolute;
            background-image: url(../images/quote-black.svg);
            background-repeat: no-repeat;
            height: 68px;
            width: 68px;
            top:30px;
            left: 440px;
            //left = media-img-width + media-img-left-right-margin
        }
        .slick-arrow
        {
            font-size: 0;
            border:none; 
            padding: 15px 30px;            
            background-color: transparent;
            background-image: url(../images/arrow-active.svg);            
            background-repeat: no-repeat;
            background-size: auto 20px;
            position: absolute;
            top:30px;;
            right: 0;
            z-index: 9;
        }
        .slick-disabled
        {
            background-image: url(../images/arrow-inactive.svg);
        }
        .slick-next
        {
            background-position:left center;
        }
        .slick-prev
        {
            right: 90px;
            transform: rotate(180deg);
            background-position:left center;
        }
        .media-img
        {
            width: 320px;
            height: 320px;
            margin: 0px 60px;
            overflow: hidden;
            border-radius: 50%;
            box-shadow: 0px 5px 5px #0000001A;
            img 
            {
                height: 100%;
                width: auto;
            }
        }
        .media-body
        {
            margin: 120px 0 0 0;
            flex: 1 1;
            p
            {

                font-size: 22px;
                line-height: 30px;
                font-weight: normal;
                margin: 0 0 30px 0;
            }
            .q-name
            {
                font-weight: 700;
                font-size: 24px;
            }
            .q-clinic
            {
                font-size: 16px;
                font-weight: normal;
            }
        }
    }    
}
.new-dentist-form
{
    background-color: $primary-color;
    color: #fff;
    h2 
    {
        color: #fff;
    }
    p 
    {
        color: #fff;
        font-size: 24px;
        font-weight: 300;
    }
    .dentist-form
    {
        margin: 15px 0 0 0;
        text-align: center;
        .show-on-change
        {
            margin: 30px 0 0 0;
            display: none;
        }
        .form-group
        {
            padding: 0 12px;
        }
        label
        {
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: left;
            width: 100%;
        }        
        input 
        {
            border: 1px solid #BCE0FD;
            border-radius: 4px;
            background-color: #fff;
            padding: 12px 15px 10px 15px;
            height: auto;
            &::-webkit-input-placeholder { /* Edge */
                color:#D9D9D9;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color:#D9D9D9;
            }

            &::placeholder {
                color:#D9D9D9;
            }
        }
        .btn-custom
        {
            margin-top: 30px;
        }
        .select-box
        {
            position: relative;          
        }
        #d-location
        {
            padding: 20px 30px 16px 30px;
            height: auto;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url(../images/dropdown-icon.svg);
            background-repeat: no-repeat;
            background-position: 91% center;
            background-size: 12px;
            cursor: pointer;
        }
        .d-location
        {
            width: 100%;
            text-transform: none;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            margin: 0 0 15px 0;
            img 
            {
                height: 18px;
                width: auto;
                position: relative;
                top:-3px;
                margin: 0 7px 0  0;
            }          
        }
    }
}
.faq 
{
    .faq-content
    {
        margin: 45px 0 0 0;
        .card
        {
            border: 1px solid #F3F3F3;
            border-radius: 6px;            
            margin: 0 0 30px 0;
            &:last-child
            {
                margin: 0;
            }
            .card-header
            {
                background-color: #fff;
                border: none;
                border-radius: 8px;
                font-size: 0;
                padding: 0;
                .btn
                {
                    color: $font-color;
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 32px;
                    padding: 17px 30px;
                    width: 100%;
                    text-align: left;
                    position: relative;                    
                    text-decoration: none;                    
                    &::after
                    {
                        content: '';
                        position: absolute;
                        height: 30px;
                        width: 30px;
                        right: 20px;
                        top:0;
                        bottom: 0;
                        margin: auto 0;
                        background-image: url(../images/dropdown-icon.svg);
                        background-repeat: no-repeat;
                        background-position:center;
                        background-size: 10px;
                        transform: rotate(0deg);
                    }
                    &:hover , &:focus
                    {
                        text-decoration: none;
                    }
                }
                .btn.collapsed
                {
                    &::after
                    {                        
                        transform: rotate(-90deg);
                    }
                }
            }
            .card-body
            {
                padding:0 30px 17px 30px;
                font-size: 16px;
                line-height: 26px;
                color: $font-color;
            }
        }
    }
}
/* --- Responsive start --- */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
    .d-banner .bg-overlay
    {
        width: 100%;
    }
    .d-banner .txt-section h1
    {
        font-size: 34px!important;
    }
    .d-banner .txt-section p
    {
        font-size: 16px;
    }
    .patients-dashboard
    {
        padding: 0 0 60px 0;
    }
    .patients-dashboard .txt-sec
    {
        width: 100%;
        padding: 0 0 0 60px;
    }
    .d-testimonials
    {
        margin: 0;
    }
    .d-testimonials .dentist_slide::after
    {
        left: 325px;
    }
    .d-testimonials .dentist_slide .media-img
    {
        width: 280px;
        height: 280px;
        margin: 0px 45px 0 0;
    }
    .d-testimonials .dentist_slide .media-body p
    {
        font-size: 18px;
        line-height: 28px;
    }
    .new-dentist-form p
    {
        font-size: 22px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) { 
    .d-banner .bg-overlay
    {
        width: 100%;
    }
    .d-banner .btn
    {
        padding: 12px 30px;
        font-size: 14px;
    }
    .patients-dashboard .txt-sec
    {
        width: 100%;
        padding: 0;
        margin: 30px 0 0 0;
        text-align: center;
    }
    .patients-dashboard .txt-sec::after
    {
        display: none;
    }
    .patients-dashboard .txt-sec p
    {
        margin: 0;
    }
    .d-testimonials
    {
        padding:120px 0 60px 0;
        margin: 0;
    }
    .d-testimonials .dentist_slide .media
    {
        display: block;
    }
    .d-testimonials .dentist_slide .media-img
    {
        width: 250px;
        height: 250px;
        margin: 0 auto;
    }
    .d-testimonials .dentist_slide .media-body
    {
        margin: 30px auto 0 auto;
        text-align: center;
    }
    .d-testimonials .dentist_slide .media-body p
    {
        font-size: 18px;
        line-height: 26px;
    }
    .d-testimonials .dentist_slide::after
    {
        //        left: 0;
        //        top:-90px;
        display: none;
    }
    .d-testimonials .dentist_slide .slick-arrow
    {
        top:-60px;
    }
    .new-dentist-form p
    {
        font-size: 18px;
    }
    .new-dentist-form .dentist-form #d-location
    {
        padding: 13px 15px 9px 15px;
        background-position: 94% center;
    }
    .faq .faq-content .card .card-header .btn::after
    {
        right: 0;
    }
    .faq .faq-content .card .card-header .btn
    {
        font-weight: 700;
        font-size: 20px;
    }
    .faq .faq-content .card .card-header .btn.collapsed
    {
        font-size: 20px;
        font-weight: 300;
    }
}
/* --- Responsive start --- */
/* --- Dentist home page end --- */