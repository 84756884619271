$info : #F2F7FE;
$pink : #FF7E85;
$primary: #3054CB;
$warning: #FFD750;
$danger: #EB3E3E;
$dark: #323344;
$light: #E9E9E9;
// @import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
$medium : 500;
$theme-fonts : #52525B;

@mixin bs-fonts {
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: $theme-fonts;
    @include media-breakpoint-down(xl) {
        font-size: 1rem;
    }   
    @include media-breakpoint-down(xl) {
        font-size: 0.875rem;
    }
   
}

h1,
.h1 {
    font-size: 3.5rem;
    color: #323344;
    margin-top: 0;
    line-height: 1.25;
    span {
        color: $pink;
    }

    @include media-breakpoint-down(xxl) {
        font-size: 3rem;
    }

    @include media-breakpoint-down(xl) {
        font-size: 2.75rem;
    }

    @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 2rem;
    }
}

h3,
.h3 {
    font-size: 2rem;
    font-weight: 500;
    @include media-breakpoint-down(xxl) {
        font-size: 1.75rem;
    }
    @include media-breakpoint-down(lg) {
        font-size: 1.5rem;
    }
}

h4,
.h4 {
    font-size: 1.5rem;
    font-weight: normal;

    @include media-breakpoint-down(xxl) {
        font-size: 1.375rem;
    }

    @include media-breakpoint-down(lg) {
        font-size: 1.25rem;
    }
}

section {
    padding: 75px 0;
    @include media-breakpoint-down(xxl) {
        padding: 60px 0;
    }  
    @include media-breakpoint-down(lg) {
        padding: 45px 0;
    }   
}

ul {
    list-style: none;
    @extend .p-0, .m-0;
}

a {
    text-decoration: none;

    &:not(.btn) {
        color: $theme-fonts;
    }
}

img {
    @extend .img-fluid;
}

.sub-title {
    font-size: 1.25rem;
    color: #A1A1AA;
    @extend .mb-4;
}

.custom-list {
    li {
        @extend .mb-3, .position-relative;
        padding-left: 30px;
        line-height: 1.5;

        &::before {
            @include bs-fonts;
            content: '\F26B';
            @extend .start-0, .position-absolute;
            top: 4px;
        }
    }
}

.common-footer-sec {
    background-image: url(../images/footer-bg.svg);
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: #F6F6F6;

    .container {
        padding-top: 75px;
        padding-bottom: 75px;
        @extend .position-relative;

        &::after {
            content: '';
            @extend .position-absolute, .end-0;
            background-image: url('../images/footer-bg-top.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            top: -95px;
            height: 200px;
            width: 200px;
            @include media-breakpoint-down(xxl) {
                height: 180px;
                width: 180px;
                top: -75px;
            }
            @include media-breakpoint-down(xl) {
                height: 150px;
                width: 150px;
            }
            @include media-breakpoint-down(lg) {
                height: 120px;
                width: 120px;
                top: -50px;
            }
            @include media-breakpoint-down(md) {
                height: 90px;
                width: 90px;
            }
        }
    }
}

.form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.select-date {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23707070' class='bi bi-calendar2-event' viewBox='0 0 16 16'%3E%3Cpath d='M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z'/%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z'/%3E%3Cpath d='M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z'/%3E%3C/svg%3E");
}

.slick-arrow {
    position: absolute;
    top: -38px;
    height: 20px;
    width: 20px;
    border: none;
    cursor: pointer;
    font-size: 0;
    background-color: transparent;
    border-radius: 50%;
    background-repeat:no-repeat;
    background-size:100%;
    transition: all 200ms ease-in;
    background-position: center;
    padding: 0;
    z-index: 2
}

.slick-arrow.slick-prev {
    right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-left-circle' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'/%3E%3C/svg%3E");
}

.slick-arrow.slick-next {
    right: 0px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-right-circle' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E");
}

.form-control
{
    &:focus
    {
        box-shadow: none;
    }
}