  /* Three column layout */
  .side {
    float: left;
    width: 15%;
    margin-top: 10px;
  }
  .side i {
    color: #FF8C00;
    margin: 0 3px 0 0;
    font-size: 14px;
  }
  .middle {
    float: left;
    width: 70%;
    margin-top: 10px;
  }
  
  /* Place text to the right */
  .right {
    text-align: right;
  }
  
  /* The bar container */
  .bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
    border-radius: 12px;
    overflow: hidden;
  }
  
  /* Individual bars */
  .bar-5 {width: 60%; height: 18px; background-color: #4CAF50;}
  .bar-4 {width: 30%; height: 18px; background-color: #9dd23e;}
  .bar-3 {width: 10%; height: 18px; background-color: #f6d67c;}
  .bar-2 {width: 4%; height: 18px; background-color: #ff9800;}
  .bar-1 {width: 15%; height: 18px; background-color: #f44336;}

  .rwnofound_badge {
	background-color: #DE443626;
	color: #e62b4a;
	text-align: center;
	padding: 25px;
	font-size: 18px;
	line-height: 18px;
	font-weight: 700;
	border: 1px solid #DE443626;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}
.rwnofound_badge i{
    margin: 0 10px 0 0;
}

  
  /* Responsive layout - make the columns stack on top of each other instead of next to each other */
  @media (max-width: 400px) {
    .side, .middle {
      width: 100%;
    }
    /* Hide the right column on small screens */
    .right {
      display: none;
    }
  }