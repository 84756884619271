.banner-sec {
    background-color: #04051AD0;
    height: 100vh;
    min-height: 600px;
    @extend .position-relative;
    z-index: 0;

    @include media-breakpoint-down(lg) {
        height: auto;
    }

    &::after {
        content: '';
        @extend .position-absolute, .w-100, .h-100, .top-0, .start-0;
        background-image: url(../images/banner-bg.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .container {
        @extend .position-relative;
        z-index: 4;
    }

    h1 {
        margin-top: 80px;
        color: #fff;
    }

    form {
        .input-group {
            box-sizing: border-box;
            @extend .w-100, .mx-lg-1, .mx-auto;
            height: 60px;

            input {
                box-sizing: border-box;
                @extend .border-start-0;
            }

            .input-group-text {
                @extend .bg-white, .text-secondary, .pe-0;
            }
        }

        .search-input {
            max-width: 360px;

            select {
                padding-left: 40px;
                background-repeat: no-repeat;
                background-position: 12px center;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(108, 117, 125)' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
            }
        }

        .location-date-input {
            max-width: 300px;
        }

        .btn {
            width: 100%;
            max-width: 200px;
            @extend .mx-1;

            @include media-breakpoint-down(lg) {
                max-width: 300px;
            }
        }

        @include media-breakpoint-down(lg) {
            .search-input {
                max-width: 300px;
            }

            .input-group {
                width: 100%;
            }
        }
    }

    .custom-list {
        li {
            max-width: 250px;
            @extend .mx-lg-4, .mx-1;

            @include media-breakpoint-down(lg) {
                max-width: none;
            }
        }
    }

    .MuiFormControl-root.MuiTextField-root.form-control {
        padding: 0.375rem 0.75rem !important;

        .MuiInputBase-formControl {
            @extend .d-flex, .h-100;

            &::before,
            &::after {
                @extend .d-none;
            }

            .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
                order: 2;
                @extend .p-0;
            }

            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                order: 1;

                button {
                    @extend .p-0, .ms-0;
                    height: 24px;
                    width: 18px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 16px auto;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(108, 117, 125)' class='bi bi-calendar-week' viewBox='0 0 16 16'%3E%3Cpath d='M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z'/%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z'/%3E%3C/svg%3E");

                    .MuiIconButton-label {
                        svg {
                            @extend .d-none;
                        }
                    }
                }

                @extend .p-0, .ms-0, .me-3;
            }
        }
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #212529 !important;
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #212529 !important;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #212529 !important;
    }

    #date-picker-dialog::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #212529 !important;
        opacity: 1;
        /* Firefox */
    }

    #date-picker-dialog:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #212529 !important;
    }

    #date-picker-dialog::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #212529 !important;
    }
}

.quality-providers {
    padding-bottom: 0;
}

.industry-sec {
    h2 {
        max-width: 780px;
        @extend .mx-auto, .mb-3;
    }

    .industry-box {
        margin: 12px 0;

        &.last-box {
            height: calc(100% - 24px);
        }
    }
}

.cta-box-sec {
    .container {
        border-radius: 20px;
        background-repeat: no-repeat;
        background-size: auto 100%;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            background-size: 100vw auto;
            background-position: bottom;
            border-radius: 0;
        }
    }


    .h1 {
        max-width: 600px;
    }

    &.cta-one .container {
        background-image: url(../images/cta-bg-small.svg);
        background-color: #04051AD0;
        background-size: 100% auto;
        background-position: bottom;

        .btn-box {
            .btn {
                min-width: 185px;
            }
        }
    }

    &.cta-two {
        .container {
            padding-top: 75px;
            padding-bottom: 75px;
            background-image: url(../images/cta-bg-big.svg);
            background-color: #04051AD0;

        }
    }

}

.success-stories-sec {
    overflow: hidden;

    .success-slider {
        margin: 0 -15px;

        .slick-list {
            overflow: visible;
        }

        .slick-slide {
            background-color: #F8FCFD;
            border-radius: 10px;
            padding: 30px;
            margin-right: 15px;
            margin-left: 15px;

            .user-strip {
                @extend .mt-5, .align-items-center;

                .img-box {
                    @extend .me-3;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .content-box {
                    .u-name {
                        font-size: 1.75rem;
                        font-weight: 700;
                        line-height: 1.25;
                    }

                    .u-location {
                        font-size: 16px;
                    }
                }
            }
        }

        .slick-arrow {
            height: 32px;
            width: 32px;
            top: -105px;
            opacity: 0.65;

        }

        .slick-prev {
            right: 60px;
        }

        .slick-next {
            right: 15px;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 90px;

            .slick-arrow {
                top: auto;
                bottom: -60px;
            }

            .slick-prev {
                left: calc(50% - 40px);
            }

            .slick-next {
                right: calc(50% - 40px);
            }
        }
    }

    .slick-track {
        @extend .d-flex;

        .slick-slide {
            height: auto !important;

            &>div {
                @extend .h-100;

                .slider-box {
                    @extend .h-100;
                }
            }
        }
    }
}



.top-providers,
.top-clinic {
    overflow: hidden;

    .provider-slider {
        margin: 0 -7px;

        .inner-box {
            background-color: #F2F7FE;
            border: 1px solid #CDDFFC;
        }
    }

    .clinic-slider {
        margin: 0 -7px;

        .inner-box {
            background-color: #FCF6E4;
            border: 1px solid #FFF0BD;
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slick-track {
        @extend .d-flex;

        .slick-slide {
            height: auto !important;

            &>div {
                @extend .h-100;

                .slider-box {
                    @extend .h-100;
                }
            }
        }
    }

    .inner-box {
        @extend .d-flex, .p-4, .h-100;
        border-radius: 10px;
        margin-right: 7px;
        margin-left: 7px;
    }

    .img-box {
        @extend .me-3;
        width: 80px;
        height: 80px;
        min-height: 80px;
        min-width: 80px;
        border-radius: 50%;
        overflow: hidden;

        @include media-breakpoint-down(xxl) {
            width: 65px;
            height: 65px;
            min-height: 65px;
            min-width: 65px;
        }

        @include media-breakpoint-down(lg) {
            width: 60px;
            height: 60px;
            min-height: 60px;
            min-width: 60px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .content-box {
        h4 {
            font-weight: $medium;

            i {
                @extend .text-primary;
                font-size: 16px;
                position: relative;
                top: -3px;
            }
        }

        .availability {
            font-size: 12px;
        }
    }

    .slick-arrow {
        height: 32px;
        width: 32px;
        top: -105px;
        opacity: 0.65;

        @include media-breakpoint-down(md) {
            top: -60px;
        }
    }

    .slick-prev {
        right: 50px;
    }

    .slick-next {
        right: 8px;
    }
}

.provider-clinic-list {
    ul {
        gap: 20px;

        li {
            width: calc(25% - 15px);

            @include media-breakpoint-down(md) {
                width: calc(50% - 15px);
            }
        }
    }
}

.selectPaymentModel {
    .modal-dialog {
        max-width: 540px;
        @extend .w-100;
    }

    .modal-content {
        @extend .border-0, .rounded-0;
    }

    .modal-header h5 {
        font-size: 22px;
        font-weight: 600;
    }

    .btn-custom {
        padding: 8px 30px 4px 30px !important;
    }

    input,
    select,
    texarea,
    button {
        &:focus {
            outline: none !important;
        }
    }

    .search-popup {
        padding: 15px 30px;
        @extend .rounded-0;

        select {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.169' height='3.943' viewBox='0 0 8.169 3.943'%3E%3Cpath id='Path_4549' data-name='Path 4549' d='M3106.946,2498h8.169l-4.273,3.943Z' transform='translate(-3106.946 -2498)' fill='%23373a3c'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: calc(100% - 15px) center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 25px;
        }

        .form-check.form-check-inline {
            margin-right: 14px;
        }

        label {
            @extend .small, .form-label;
            font-weight: 600;
        }
    }
}

.app-ss-slider {
    background-image: url(../images/thousand-providers.svg);
    background-size: 100% auto;
    background-repeat: no-repeat;

    .slick-slide {
        @extend .d-flex, .justify-content-center;

        img {
            max-width: 350px;

            @include media-breakpoint-down(xxl) {
                max-width: 280px;
            }

            @include media-breakpoint-down(xl) {
                max-width: 250px;
            }

            @include media-breakpoint-down(lg) {
                max-width: 200px;
            }
        }
    }
}