.main-grid-sec {
    .practice-sidebar {
        background-color: #F6F6F6;
        border-radius: 10px;

        .book-sec {
            label {
                font-size: 1.375rem;
                font-weight: 500;

                @include media-breakpoint-down(xxl) {
                    font-size: 1.25rem;
                }

                @include media-breakpoint-down(lg) {
                    font-size: 1.125rem;
                }
            }

            .label-small {
                font-size: 1rem;
            }
            .label-medium {
                font-size: 1.5rem;
            }
        }

        #availability-tab {
            border: 1px solid #A1A1AA;
            border-radius: 6px;

            .nav-link {
                color: #323344;
            }

            .nav-link.active {
                @extend .bg-dark, .text-white;
            }
        }

        #pills-tabContent {
            .avi-time {
                margin: 0 -4px;

                a {
                    @extend .d-block, .px-1;
                    width: calc(20% - 8px);
                    margin: 4px;
                    font-size: 14px;

                    @include media-breakpoint-down(xxl) {
                        width: calc(25% - 8px);
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 13px;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                    }
                }
            }
        }

        .is-patient .form-check-box {
            width: calc(50% - 7px);
        }

        .is-patient .form-check-box,
        .flexy-selection .select-slot,
        .flexy-selection .select-day {
            position: relative;
        }

        .is-patient .form-check-box input,
        .flexy-selection .select-slot input,
        .flexy-selection .select-day input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 2;
        }

        .is-patient .form-check-box label {
            background-color: #EAEAEA;
            color: #707070;
            text-align: center;
            padding: 6px 10px;
            font-size: 1rem;
            border: 1px solid #95989A;
            border-radius: 6px;
            font-weight: 500;
            width: 100%;
            cursor: pointer;
        }

        .flexy-selection .select-slot {
            width: calc(25% - 5px);
        }

        .flexy-selection .select-slot label {
            background-color: #EAEAEA;
            color: #707070;
            text-align: center;
            padding: 6px 10px;
            font-size: 12px;
            border: 1px solid #95989A;
            border-radius: 6px;
            font-weight: normal;
            width: 100%;
            height: 100%;
            @extend .d-flex, .justify-content-center, .align-items-center;
        }

        .days-selection {
            @include media-breakpoint-down(lg) {
                max-width: 320px;
            }
        }

        .flexy-selection .select-day label {
            background-color: #EAEAEA;
            color: #707070;
            text-align: center;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            line-height: 40px;
            font-size: 1rem;
            border: 1px solid #95989A;
            font-weight: normal;

            @include media-breakpoint-down(md) {
                height: 32px;
                width: 32px;
                line-height: 32px;
                font-size: 14px;
            }
        }

        .is-patient .form-check-box input:checked+label,
        .flexy-selection .select-slot input:checked+label,
        .flexy-selection .select-day input:checked+label {
            background-color: #0A0A0A;
            color: #fff;
            border-color: #0A0A0A !important;
        }
    }

    .tab-box {
        margin: 0 0 30px 0;
        padding: 0 0 30px 0;
        font-size: 16px;

        @include media-breakpoint-down(md) {
            font-size: 14px;

            a.btn-link {
                font-size: 14px !important;
            }
        }
    }

    #tab-reviews {
        .review-box-nw {
            border-radius: 10px;
            background-color: #F6F6F6;
            padding: 30px;

            .rate-stars {
                @extend .mb-2;

                i {
                    color: #FF8C00;
                }
            }
        }
    }

    .tab-view-practice {
        h4 {
            font-size: 1.375rem;
            font-weight: 500;

            @include media-breakpoint-down(xl) {
                font-size: 1.25rem;
            }

            @include media-breakpoint-down(md) {
                font-size: 1.125rem;
            }
        }
    }

    #tab-about,#tab-about-mob,
    #tab-reviews {
        .review-detail-box {
            .avg-rating {
                strong {
                    font-size: 2rem;
                }
            }

            .rate-stars {
                @extend .mb-2;
                gap: 4px;

                i {
                    @extend .d-inline-block;
                    background-color: #FF8C00;
                    height: 26px;
                    width: 26px;
                    border-radius: 50%;
                    @extend .text-white;
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }

    #tab-about, #tab-about-mob {
        .about-dr {
            gap: 30px;

            .profile {
                .content-box {
                    font-size: 14px;

                    &>div {
                        @extend  .mt-2;
                        line-height: 1.25;
                    }
                }

                .img-box {
                    width: 140px;
                    height: 140px;
                    aspect-ratio: 1/1;
                    @extend .border;

                    @include media-breakpoint-down(xxl) {
                        width: 120px;
                        height: 120px;
                    }



                    img {
                        @extend .w-100, .h-100;
                        object-fit: cover;
                    }
                }
            }
        }

        .more-pics {
            a {
                @extend .d-block, .text-center;
                width: 140px;

                @include media-breakpoint-down(xxl) {
                    width: 120px;
                }
            }
        }

        .top-review {
            background-color: #F6F6F6;
            border-radius: 10px;

            i {
                color: #FF8C00;
            }
        }

        #profile-faq {
            .accordion-item {
                &:not(:last-child) {
                    @extend .mb-4;
                }
            }

            .accordion-button {
                box-shadow: none;
                padding: 0;
                color: #323344;
                font-size: 1.125rem;
                font-weight: 500;
            }

            .accordion-button::after {
                background: none;
                content: '\F2EA';
                @include bs-fonts;
                transform: none;
                font-weight: 900;
                font-size: 20px;
                color: #000;
            }

            .collapsed.accordion-button::after {
                background: none;
                content: '\F4FE';
                @include bs-fonts;
                transform: none;
                font-weight: 900;
                font-size: 20px;
                color: #000;
            }
        }
    }

    #tab-photos {
        .photo-slider {
            @extend .position-relative;
            margin: 15px 0;

            .img-box {
                aspect-ratio: 2/2;
                overflow: hidden;                
                padding: 1px;

                img {
                    @extend .w-100, .h-100;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
        }
    }

    .list-box {
        ul {
            @extend .mt-3;

            li {
                width: calc(100% / 3);
                margin: 0 0 15px 0;

                @include media-breakpoint-down(xxl) {
                    font-size: 14px;
                }

                @include media-breakpoint-down(xl) {
                    font-size: 12px;
                    width: calc(100% / 2);
                }

                @include media-breakpoint-down(md) {
                    font-size: 12px;
                    width: 100%;
                }
            }
        }
    }

    #tab-hours {
        .inner-box {
            gap: 45px;

            .hours-box {
                min-width: 350px;

                @include media-breakpoint-down(xl) {
                    min-width: 300px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                h4 {
                    span {
                        @extend .text-primary;
                        font-size: 16px;
                    }
                }

                ul {
                    li {
                        @extend .d-flex, .justify-content-between, .align-items-center;

                        &:not(:last-child) {
                            @extend .border-bottom, .mb-2, .pb-2;
                        }

                        &.active {
                            font-weight: 500;
                        }

                        .text-muted {
                            color: #A1A1AA !important;
                        }
                    }
                }
            }
        }
    }

    #tab-providers {
        ul {
            li {
                @extend .d-flex, .p-4, .justify-content-between, .align-items-center, .border, .rounded, .flex-wrap;

                &:not(:last-child) {
                    @extend .mb-3;
                }

                .provider-info {
                    @extend .d-flex, .align-items-center, .mb-md-0, .mb-3;

                    h4 {
                        font-size: 1.25rem;
                        @extend .mb-1;
                    }

                    .img-box {
                        aspect-ratio: 1/1;
                        max-width: 90px;
                        min-width: 90px;
                        @extend .rounded-circle, .overflow-hidden;

                        @include media-breakpoint-down(md) {
                            max-width: 64px;
                        }

                        img {
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

.highlight-box {
    height: 100%;

    .img-box {
        min-width: 30px;
    }
}


.first-available-items-item__slot-item.active
{
    @extend .text-white;
}
