.btn-signing {
    background-color: #FFE8E8;
    border-radius: 4px;
    color: #EB3E3E;

    &:hover {
        background-color: #ffcfcf;
        color: #EB3E3E;
    }
}

.listing-data-sec {
    @extend .position-relative;

    .map-view {
        background-color: #F6F6F6;
        height: calc(100vh - 79px);
        top: 79px;
        @extend .position-sticky, .text-end, .ms-auto, .d-none, .d-lg-block;
        width: calc(((100vw - 1320px)/2) + 100% + 4px);

        @include media-breakpoint-down(xxl) {
            width: calc(((100vw - 1140px)/2) + 100% + 4px);
        }

        @include media-breakpoint-down(xl) {
            width: calc(((100vw - 960px)/2) + 100% + 4px);
        }

        #gmap_canvas {
            @extend .w-100, .h-100;
        }

        .filterSec {
            .form-check {
                top: 15px;
                @extend .start-0, .end-0, .mx-auto, .my-0, .position-absolute, .p-0;
                width: calc(100% - 40px);
                z-index: 3;
                box-shadow: 0 10px 15px rgba(37, 90, 150, .10196078431372549);

                .form-control {
                    padding: 15px 15px 15px 30px;
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.278' height='15.038'%3E%3Cpath d='M5.639 15.038s5.639-5.344 5.639-9.4A5.639 5.639 0 100 5.639c0 4.055 5.639 9.399 5.639 9.399zm0-6.579a2.82 2.82 0 112.82-2.82 2.82 2.82 0 01-2.82 2.82z' fill='%23999'/%3E%3C/svg%3E");
                    background-position: 10px;
                    background-repeat: no-repeat;
                    background-size: auto 16px;
                }
            }
        }
    }

    #listing-tab {
        .nav-item {
            &:not(:last-child) {
                @extend .me-4;
            }
        }

        .nav-link {
            @extend .border-0, .position-relative, .pb-3, .px-0;
            color: #A1A1AA;
            cursor: pointer;

            &::after {
                content: '';
                @extend .position-absolute, .start-0, .bottom-0, ;
                height: 2px;
                background-color: #52525B;
                width: 0;
            }

            &.active {
                font-weight: $medium;
                color: #52525B;

                &::after {
                    @extend .w-100;
                }
            }
        }
    }

    #listingTabContent {

        @include media-breakpoint-down(md) {
            form {
                .col-6 {
                    padding: 0 7px;
                }
            }
        }

        .btn-outline-secondary {
            border-color: #ced4da;
            color: #52525B;

            &:hover {
                background-color: transparent;
                color: #52525B;
            }
        }

        .date-selector {
            max-width: 150px;

            .MuiFormControl-root.select-date {
                @extend .border, .rounded;
                padding: .375rem .75rem;
                height: 38px;
            }

            #date-picker-dialog {
                @extend .p-0, .position-relative;
                top: 3px;
            }

            .MuiInput-underline {

                &::after,
                &::before {
                    @extend .d-none;
                }
            }

            .MuiButtonBase-root.MuiIconButton-root {
                @extend .p-0;
                height: 20px;
                width: 20px;
            }

            .MuiIconButton-label {
                @extend .d-none;
            }

            @include media-breakpoint-down(md) {
                max-width: none;
            }

            .MuiSvgIcon-root {
                @extend .d-none;
            }

            #date-picker-dialog-helper-text {
                margin-top: -3px;
            }
        }

        .provider-info {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .provider-availability {
            max-width: 340px;
            width: 100%;

            @include media-breakpoint-down(lg) {
                max-width: 320px;
            }

            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }

            .availability-info {
                .info-box {
                    @extend .text-start, .fw-normal, .p-2;
                    width: calc((100% / 5) - 5px);
                    margin-bottom: 5px;

                    @include media-breakpoint-down(xl) {
                        font-size: 12px !important;
                    }

                    .avi-date {
                        @extend .mb-2;
                        line-height: 1.5;
                    }

                    &.yes-avi {
                        @extend .badge, .bg-primary;
                    }

                    &.not-avi {
                        @extend .badge, .bg-light;
                        color: #979797;
                    }

                }
            }
        }

        .provider-facalities {
            span {
                @extend .me-2, .mb-2, .position-relative, .d-block;
                padding: 10px 12px 10px 25px;
                font-size: 10px;

                &::before {
                    @include bs-fonts;
                    content: '\F26A';
                    @extend .position-absolute;
                    left: 10px;
                    top: 10px;
                }
            }
        }
    }

    .img-box {
        width: 90px;
        height: 90px;
        min-width: 90px;
        min-height: 90px;
        border-radius: 50%;
        overflow: hidden;
        @extend .me-3;

        @include media-breakpoint-down(xxl) {
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
        }

        @include media-breakpoint-down(xl) {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
        }

        img {
            @extend .img-fluid;
            object-fit: cover;
        }
    }

    .content-box {
        font-size: 14px;

        .pro-name {
            font-size: 1.25rem;
            font-weight: $medium;

            @include media-breakpoint-down(xl) {
                font-size: 1.125rem;
            }
        }

        .pro-designation {
            color: #707070;
        }

        .pro-location {
            span {
                max-width: 200px;
                width: 100%;
            }
        }
    }
}

.filter-popup {
    outline: none;

    &:focus {
        outline: none;
    }

    .modal-dialog {
        outline: none;
        border: none !important;

        &:focus {
            outline: none;
            border: none !important;
        }
    }

    .modal-header,
    .modal-footer,
    .modal-body {
        padding: 1rem 2rem;
        border: none;
    }

    .more-filter-box {
        .form-check .form-check-input {
            margin-top: 2px;
        }

        .form-check-label {
            padding-left: 7px;
        }

        .form-label {
            font-weight: 600;
            font-size: 18px;
        }

        .text-muted {
            color: #95989a !important;
            font-size: 14px;
        }
    }

    .btn-custom {
        padding: 8px 30px 4px 30px !important;
    }

    .modal-content {
        padding: 15px 7px;
        border: none !important;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        min-width: 180px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }

    .modal-header {
        .close {
            @extend .btn-close;

            span {
                display: none;
            }
        }
    }
}

input,
select,
texarea,
button {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}




.provider-list-cal {
    .provider-list-box {
        li {
            padding: 30px 0;
            border-top: 1px solid #e9e9e9;
            border-bottom: 1px solid #e9e9e9;
            margin-top: -1px;

            &:first-child {
                border-top: none;
            }
        }

        .left-sec {
            width: calc(100% - 380px);

            .pro-sub-txt {
                font-size: 14px;
                font-weight: 600;
            }

            .pro-main-txt {
                font-size: 18px;
                font-weight: 600;
            }

            .pro-dent-link {
                a {
                    color: #2e88cd;
                    cursor: pointer;
                }
            }

            .pro-add {
                color: #95989a;
            }

            .media {
                .media-img {
                    .img-box {
                        height: 70px;
                        width: 70px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: 1px solid #d8d8d8;
                        background-color: #fefefe;
                    }

                    .btn-link {
                        color: #2e88cd;
                        display: block;
                        font-size: 14px;
                    }
                }
            }
        }

        .right-sec {
            width: 360px !important;

            .time-slot-sec {
                margin-bottom: 15px;

                span {
                    background-color: #e9e9e9;
                    color: #95989a;
                    display: inline-block;
                    height: 34px;
                    width: 64px;
                    text-align: center;
                    margin: 4px;
                    line-height: 38px;
                    font-size: 13px;
                }

                .asigned-slot {
                    background-color: #95989a;
                    color: #fff;
                    cursor: pointer;
                }

                .moreTimeBtn {
                    cursor: pointer;
                }
            }

            .border-btn {
                border: 1px solid #2e88cd;
                border-radius: 4px;
                background-color: rgba(46, 136, 205, 0.1);
                color: #2e88cd;
                padding: 8px 15px 4px 15px;
                margin: 7px 4px;

                img {
                    position: relative;
                    top: -2px;
                }
            }

            .info-txt {
                color: #9d9d9d;
                font-size: 14px;
                margin-bottom: 15px;
            }

            .cd-dr-img {
                padding-right: 20px;
            }

            .cd-dr-img .img-box {
                height: 64px;
                width: 64px;
                min-width: none;
                min-height: none;
                overflow: hidden;
                border: 1px solid #ffffff;
                border-radius: 50%;
                margin: 0 -15px 0 0 !important;
                display: inline-block;
            }
        }
    }

}

#providerList .border-btn,
#providerList .border-btn:hover {
    border: 1px solid #2e88cd;
    border-radius: 4px;
    background-color: rgba(46, 136, 205, 0.1);
    color: #2e88cd;
    padding: 8px;
    margin: 7px 4px;
    min-width: 250px;
    @extend .d-flex, .justify-content-center, .align-items-center;
}

.authentication-box {
    label {
        font-size: 16px;
    }
    .form-group
    {
        @extend .mb-3
    }
}
.confirm-text {
    border: 1px solid #0267D4;
    background-color: #E5F1FF; 
    border-radius: 20px; 
    max-width: 90%;
    margin: 20px auto;
}

.confirm-text .confirm-text-item {
    padding: 20px;
    border-bottom: 1px solid #C9E0FA;
    text-align: center;
}
.confirm-text .confirm-text-item:last-child {
    border-bottom: none;
}
.confirm-text .confirm-text-item .confirm-text-item-title{
    color: #0267D4 !important;
    text-align: center;
}
.practice-sidebar 
{
	.list-group-item
	{
		border-radius: 4px!important;
	}
}

@media only screen and (max-width: 767px){
    .provider-list-cal .provider-list-box .right-sec {
        width: 100% !important;
    }
    .provider-list-cal {
        .provider-list-box {
            .left-sec {
                width: calc(100% - 0px);
            }
        }
    }
    .provider-list-cal .provider-list-box .left-sec .media .media-img .img-box {
        height: 70px;
        width: 70px;
        margin-right: 0px !important;
    }
    .listing-data-sec .cd-dr-img .img-box {
        width: 40px !important;
        height: 40px !important;
        min-width: 40px;
        min-height: 40px;
    }
    .provider-list-cal .provider-list-box .right-sec .cd-dr-img{
        margin: 10px 0px 0px;
        width: 90px;
        text-align: left;
        padding-right: 10px;
    }
    .provider-list-cal .provider-list-box .left-sec .media .media-img .btn-link{
        font-size: 13px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px){
    .provider-list-cal .provider-list-box .left-sec {
        width: calc(100% - 260px);
    }
}