.main-header {
  @extend .border-bottom;

  .navbar-brand {
    img {
      height: 36px;
      width: auto;
    }
  }

  ul {
    li:not(:last-child) {
      @extend .me-md-4;
    }

    li {
      a:not(.btn) {
        color: #52525B;
      }
    }
  }

  @include media-breakpoint-down(md) {
    #toggle {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      right: 0;
      height: 26px;
      width: 30px;
      cursor: pointer;
      z-index: 100;
      transition: opacity .25s ease;
    }

    #toggle:hover {
      opacity: .7;
    }

    #toggle.active .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: #FFF;
    }

    #toggle.active .middle {
      opacity: 0;
      background: #FFF;
    }

    #toggle.active .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: #FFF;
    }

    #toggle span {
      background: #222222;
      border: none;
      height: 3px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .35s ease;
      cursor: pointer;
    }

    #toggle span:nth-of-type(2) {
      top: 11px;
    }

    #toggle span:nth-of-type(3) {
      top: 22px;
    }

    #overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      background: rgba(34, 34, 34, 0.95);
      top: 0;
      left: 0;
      width: 100%;
      height: 0%;
      opacity: 0;
      visibility: hidden;
      transition: opacity .35s, visibility .35s, height .35s;
      overflow: hidden;
      z-index: 9;
    }

    #overlay.open {
      opacity: 1;
      visibility: visible;
      height: 100%;

      ul {
        li {
          text-align: center;
          font-size: 1.5rem;
          margin: 15px 0;

          a {
            &:not(.btn) {
              color: #fff;
            }

            display: inline-block;

            &.btn {
              border: none !important;
              padding: 10px 60px !important;
              font-size: 1.5rem;
            }

            &.btn-outline-secondary {
              background-color: #3054CB !important;
              color: #fff;
            }
          }
        }
      }
    }

    #overlay ul li a {
      display: block;
      position: relative;
      text-decoration: none;
      overflow: hidden;
    }

    #overlay ul li a:hover:after,
    #overlay ul li a:focus:after,
    #overlay ul li a:active:after {
      width: 100%;
    }

    #overlay ul li a:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0%;
      transform: translateX(-50%);
      height: 3px;
      background: #FFF;
      transition: .35s;
    }

    #overlay ul li a.btn-signing:after {
      background-color: #eb3e3e;
    }


  }
}

body.open {
  overflow: hidden;
}

.main-header {
  .mobile-nav .nav-link {
    font-size: 16px !important;
  }
}